import * as React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Dump from "../components/dump.tsx";
import type { PageRenderedItemData, PageRenderedSectionData } from "../rest.interface";
import { ContentRenderMode } from "./contentRenderMode";
import RenderContent from "./renderContent";
import { getMarginClasses, getPaddingClasses, prefixClassArray } from "./renderFunctions.ts";
import RenderSection from "./renderSection";
import { clsx } from 'clsx';

interface Props extends React.ComponentPropsWithoutRef<"div"> {
	item: PageRenderedItemData
}

export default function RenderSlider({item}: Props) {
	const tailwindSizes = {
		sm: 480,
		md: 768,
		lg: 1024,
		xl: 1440,
		'2xl': 1920,
	};

	let breakpoints = {};

	for (let screenMode in tailwindSizes) {
		if (
			(item?.sliderSlidesPerView && item?.sliderSlidesPerView.hasOwnProperty(screenMode) && item?.sliderSlidesPerView[screenMode]) ||
			(item?.sliderSpaceBetween && item?.sliderSpaceBetween.hasOwnProperty(screenMode) && item?.sliderSpaceBetween[screenMode])
		) {
			breakpoints[tailwindSizes[screenMode]] = {};

			if (item?.sliderSlidesPerView && item?.sliderSlidesPerView[screenMode]) {
				breakpoints[tailwindSizes[screenMode]].slidesPerView = parseInt(item.sliderSlidesPerView[screenMode]);
			}

			if (item?.sliderSpaceBetween && item?.sliderSpaceBetween[screenMode]) {
				breakpoints[tailwindSizes[screenMode]].spaceBetween = parseInt(item.sliderSpaceBetween[screenMode]);
			}
		}
	}

	let dimensionsClasses = [];

	if (item?.sliderDimensions) {
		dimensionsClasses = dimensionsClasses.concat(getMarginClasses(item.sliderDimensions));
		dimensionsClasses = dimensionsClasses.concat(getPaddingClasses(item.sliderDimensions));
	}

	// console.log(breakpoints, dimensionsClasses);

	return (<>
		<Swiper
			className={clsx("swiper", prefixClassArray(dimensionsClasses))}
			modules={[Navigation, Pagination, Autoplay]}
			speed={500}
			loop={item.sliderLoop}
			navigation={item.sliderNavigation}
			autoplay={item.sliderAutoplay ? {
				delay: item.sliderDelay
			}: false}
			pagination={item.sliderPager}
			wrapperClass={clsx("swiper-wrapper", item.sliderCenteredSlides && "items-center")}
			slidesPerView={item.sliderSlidesPerView?.all}
			spaceBetween={item.sliderSpaceBetween?.all}
			breakpoints={breakpoints}
		>
			{item && item.page && item.page.content.map((section: PageRenderedSectionData, index) => {
				return (
					<SwiperSlide className="swiper-slide" key={'section' + index}>
						{!section?.reusable ?
							<RenderSection section={section} index={index} mode={ContentRenderMode.SLIDER}/> :
							<RenderContent content={section?.page?.content} mode={ContentRenderMode.SLIDER}/>
						}
					</SwiperSlide>
				);
			})}
		</Swiper>

		{/*<Dump value={item.sliderPager}/>*/}
		{/*<Dump value={item.sliderNavigation}/>*/}
		{/*<Dump value={item.sliderAutoplay}/>*/}
		{/*<Dump value={item.sliderDelay}/>*/}
		{/*<Dump value={item.sliderCenteredSlides}/>*/}
		{/*<Dump value={item.sliderDimensions}/>*/}
		{/*<Dump value={item.sliderSlidesPerView}/>*/}
		{/*<Dump value={breakpoints}/>*/}
		{/*<Dump value={item.sliderSpaceBetween}/>*/}
	</>);
}
