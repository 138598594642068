import * as React from 'react';
import { memo } from "react";
import { AssetMode } from "../mediaLibrary/asset.interface.ts";
import { getImgUrl } from "../mediaLibrary/assetUtils.ts";
import type { PageData } from "../rest.interface.ts";
import { format } from "date-fns";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
	news: PageData
}

const NewsBox = ({news, ...otherProps}: Props) => {

	return (<>
		<div className="w-full md:w-1/2 lg:w-1/3 px-8 2xl:px-16 mb-16">
			<div className="flex flex-col h-full bg-white rounded-3xl overflow-hidden">
				<img
					src={news?.cover && getImgUrl(AssetMode.Thumb, news.coverId, news.cover.filename, 443, 193)}
					alt=""
					width={443}
					height={193}
					className="w-full"
				/>
				<div className="flex flex-col h-full px-8 xl:px-16 py-8 items-start">
					{/*<p className="text-base">{format(news.obj.creationDate, "dd / MM / yyyy")}</p>*/}
					<h4 className="my-8 md:mb-12 text-primary-400">{news.title}</h4>
					{news.blurb && <p className="mb-8 md:mb-12">{news.blurb}</p>}
					<div className="flex-grow"/>
					<a href={news.absoluteUrl} className="button outlined black" title={news.title}>READ MORE</a>
				</div>
			</div>
		</div>
	</>);
};

export default memo(NewsBox);
