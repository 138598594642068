import * as React from 'react';
import { memo } from "react";
import { AssetMode } from "../mediaLibrary/asset.interface.ts";
import { getImgUrl } from "../mediaLibrary/assetUtils.ts";
import type { PageData } from "../rest.interface.ts";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
	item: PageData
}

const Service = ({item, ...otherProps}: Props) => {

	return (<>
		<div className="flex flex-col h-full overflow-hidden">
			<img
				src={item?.cover && getImgUrl(AssetMode.Thumb, item.coverId, item.cover.filename, 443, 250)}
				alt=""
				width={443}
				height={250}
				className="w-full rounded-3xl"
			/>
			<div className="flex flex-col h-full py-8 items-start">
				<h4 className="my-8 md:mb-12 text-primary-400">{item.title}</h4>
				{item.blurb && <p className="mb-8 md:mb-12">{item.blurb}</p>}
				<div className="flex-grow"/>
				<a href={item.absoluteUrl} className="button" title={item.title}>FIND OUT MORE</a>
			</div>
		</div>
	</>);
};

export default memo(Service);
